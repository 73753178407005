import React, { useEffect, useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { State } from '../../types';
import { LayoutHeaderState } from '../../store/ducks/app/types';
import { domain, Context } from '../../config';

export function Header() {
    const { locale } = useContext(Context);
    const history = useHistory();
    const { pathname } = useLocation();
    const header: LayoutHeaderState = useSelector(
        (state: State) => state.layouts.header
    );

    return (
        <header
            className={cx(
                'header',
                locale === 'ru' && 'header--ru',
                pathname.indexOf('careers') !== -1 && 'header--black',
                pathname.indexOf('careers') !== -1 && 'header--always-black'
            )}
        >
            <div className="header__container">
                <div className="logo header__logo">
                    <Link to="/" className="logo__img">
                        <img
                            src={`${domain}${header.logo}`}
                            alt="Overbest"
                            className="logo__img"
                        />
                        <img
                            src={`${domain}${header.logo_black}`}
                            alt="Overbest"
                            className="logo__img"
                        />
                    </Link>
                </div>
                {header.menu.length > 0 && (
                    <div className="menu header__menu">
                        <div className="menu__wrap">
                            <ul className="menu__list">
                                {header.menu.map((item, key) => (
                                    <li key={key} className="menu__item">
                                        {locale === 'en' && (
                                            <Link
                                                to={item.Link}
                                                className="menu__link"
                                            >
                                                {item.Title}
                                            </Link>
                                        )}
                                        {locale === 'ru' && (
                                            <a
                                                href={`#${item.Link}`}
                                                className="menu__link"
                                            >
                                                {item.Title}
                                            </a>
                                        )}
                                    </li>
                                ))}
                            </ul>
                            <a
                                href={header.button.link}
                                className="menu__btn-mob"
                            >
                                {header.button.title}
                            </a>
                        </div>
                    </div>
                )}
                <a
                    href={header.button.link}
                    className="menu__bt button button--medium"
                >
                    {header.button.title}
                </a>
                <div className="menu__btn-icon"></div>
            </div>
        </header>
    );
}
